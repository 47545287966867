@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}


body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:focus {
  outline:none !important;
}
